import { useContext } from 'react'

import { featureFlaggingContext } from './context.js'

export const FeatureFlagHydrationScript = () => {
  const data = useContext(featureFlaggingContext)

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `window.ldData = ${JSON.stringify(data)};`,
      }}
      suppressHydrationWarning
    />
  )
}
