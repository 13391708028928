const stringifyUnknownError = (value: unknown) => {
  if (value instanceof Error) {
    return String(value)
  }

  if (typeof value === 'object') {
    return JSON.stringify(value)
  }

  return String(value)
}

export const wrapError = (message: string, error: unknown) =>
  new Error(`${message}: ${stringifyUnknownError(error)}`, { cause: error })
