// Work around for hydration issues from external scripts modifying the DOM.  There is likely a better way to handle this.

import { useEffect } from 'react'

let scriptsAdded = false

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- We don't care what the types are for these external scripts
type TODO = any

// TODO: this should be managed by Google Tag Manager - need to coordinate a time to remove this.
const addGoogleAnalyticsScript = () => {
  const anyWindow = window as TODO
  const script = document.createElement('script')
  script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-36412393-1'
  script.async = true
  document.head.appendChild(script)

  anyWindow.dataLayer = anyWindow.dataLayer || []
  function gtag(...args: TODO[]) {
    anyWindow.dataLayer.push(args)
  }
  gtag('js', new Date())
  gtag('config', 'UA-36412393-1')
  gtag('config', 'UA-36412393-10')
  gtag('config', 'UA-36412393-9')
}

export const CustomExternalScripts = () => {
  useEffect(() => {
    if (scriptsAdded) return

    addGoogleAnalyticsScript()

    scriptsAdded = true
  }, [])

  return null
}
