import { Button } from '@navinc/base-react-components/wayfinder'

export const PreviewBar = () => (
  <div className="bg-error p-200 text-white">
    <div className="mx-auto flex max-w-[900px] flex-row justify-between">
      In preview mode. Some content may not be live.
      <form action="/api/exit-preview" method="post" className="inline-block">
        <Button type="submit" variant="primary">
          Exit Preview Mode
        </Button>
      </form>
    </div>
  </div>
)
