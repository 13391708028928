import '@navinc/base-react-components/wayfinder/styles.css'

import { LoaderFunctionArgs } from '@remix-run/node'
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { ReactNode } from 'react'
import { createHead } from 'remix-island'
import { ClientOnly } from 'remix-utils/client-only'
import { ExternalScripts, ScriptDescriptor } from 'remix-utils/external-scripts'

import { withCache } from './.server/cache.js'
import { fetchPageLayout } from './_api/fetchPageLayout.js'
import { PreviewBar } from './_components/PreviewBar/index.jsx'
import { Providers } from './_providers/index.js'
import { isPreviewMode } from './_utilities/preview-mode.server.js'
import { CustomExternalScripts } from './external-scripts.jsx'
import { clientEnv } from './lib/env.js'
import { captureError } from './lib/error-reporting.js'
import { FeatureFlagHydrationScript } from './lib/feature-flagging/feature-flag-script.jsx'
import { urlHasNoCache } from './lib/url-has-no-cache.js'
import { wrapError } from './lib/wrap-error.js'
import { ErrorPage } from './routes/error-page.jsx'

export const shouldRevalidate: ShouldRevalidateFunction = () => {
  return false
}

const getErrorProps = (error: unknown) => {
  if (isRouteErrorResponse(error) && error.status === 404) {
    return {
      title: 'Looks like you took a wrong turn',
      message: "The page you're looking for doesn't exist. Let's get you home.",
      actions: undefined,
    }
  }

  captureError(wrapError('Error boundary caught an error', error), {
    skipSnag: isRouteErrorResponse(error) && error.status === 405,
  })

  return {
    title: 'Something went wrong',
    message: undefined,
    actions: undefined,
  }
}

export function ErrorBoundary() {
  const error = useRouteError()

  const { title, message, actions } = getErrorProps(error)

  return <ErrorPage title={title} message={message} actions={actions} />
}

// Static head contents are in entry.server.tsx
export const Head = createHead(() => (
  <>
    <Meta />
  </>
))

export function Layout({ children }: { children: ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root')
  const { env, isPreview } = data ?? {}

  return (
    <>
      <Head />
      <Links />
      <Providers>
        <div className="flex h-full flex-col">
          {isPreview && (
            <div className="fixed bottom-0 z-[1000] w-full">
              <PreviewBar />
            </div>
          )}
          <div className="flex w-full grow flex-col">{children}</div>
        </div>
      </Providers>
      <ScrollRestoration getKey={(location) => location.key} />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.env = ${JSON.stringify(env)}`,
        }}
      />
      <FeatureFlagHydrationScript />
      <Scripts />
      <ClientOnly>{() => <ExternalScripts />}</ClientOnly>
      <CustomExternalScripts />
    </>
  )
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const isPreview = await isPreviewMode(request)

  const { header, footer } = await withCache('loader:root', fetchPageLayout, {
    disable: isPreview || urlHasNoCache(request.url),
  })

  return {
    env: clientEnv,
    header,
    footer,
    isPreview,
  }
}

export default function App() {
  return <Outlet />
}

// TODO: include loader data here
interface AppHandle {
  scripts?: (ScriptDescriptor & { key: string })[]
}

export const handle: AppHandle = {
  scripts: [
    {
      key: 'tealium',
      src: clientEnv.TEALIUM_SCRIPT_URL,
      type: 'text/javascript',
      async: true,
    },
  ],
}
